<template>
  <section class="about" id="about">
      <img class="parallax about-trait" style="top: 15vh; left: 4vw" data-top="15vh" data-left="4vw" src="SVG/svgAbout/Trait_Plume_Illustrator.svg"/>
      <img class="parallax about-green-spark" style="top: 15vh; left: 35vw" data-top="20vh" data-left="35vw" src="SVG/svgAbout/Spark_x1_couleurvert_apropos.svg"/>
      <img class="parallax about-spark" style="top: 20vh; left: 90vw" data-top="20vh" data-left="90vw" src="SVG/svgAbout/Spark_x2_HautDroite_apropos.svg"/>
      <img class="parallax about-spark2" style="top: 85vh; left: 10vw" data-top="85vh" data-left="10vw" src="SVG/svgAbout/Spark_x1_BasGauche_apropos.svg"/>
      <img class="parallax about-cloud" style="top: 80vh; left: 40vw" data-top="80vh" data-left="40vw" src="SVG/svgAbout/Cloud_basdroite_apropos.svg"/>
      <div class="about-container">
        <div class="about-left">
            <div class="about-picture">
            </div>
            <img src="SVG/svgAbout/Big_Spark_white.svg" />
        </div>
        <div class="about-right">
          <div class="about-title">
              <img src="SVG/svgAbout/A_PROPOS_text.svg">
              <img src="SVG/svgAbout/Lunettes.svg">
          </div>
          <div class="about-info bubble">
              <p>
                Agé de 22 ans, je réside dans la région des Hauts-de-France.<br/>
                Mes centres d'intérêts sont la musique, les jeux-vidéos et la tech.<br/>
              </p>
          </div>
          <div class="about-scroll bubble">
              <p>
                  Scrollez, si vous voulez en savoir plus sur mon travail !
              </p>
          </div>
          <div class="about-mouse-scroll">
            <img src="SVG/svgAbout/Mouse.svg">
          </div>
        </div>
      </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'AboutView',
    components: {
    }
}
</script>