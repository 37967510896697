<template>
  <section class="projet" id="projet">
    <div class="projet-title">
      <img src="SVG/svgProjet/PROJET_text.svg"/>
    </div>
    <div class="projet-filter">
      <span @click="active = 'tous'" :class="active === 'tous' ? 'active' : ''">Tous</span>
      <span @click="active = 'ui'" :class="active === 'ui' ? 'active' : ''">ui-design</span>
      <span @click="active = 'graphisme'" :class="active === 'graphisme' ? 'active' : ''">graphisme</span>
      <span @click="active = 'pao'" :class="active === 'pao' ? 'active' : ''">pao</span>
    </div>
    <div class="projet-liste">
      <projet v-for="projet in projetsToShow" :key="projet.id" :projet="projet"/>
    </div>
  </section>
</template>
<script>
import Projet from '@/components/Projet'
export default {
  name: "ProjetView",
  components: {
    Projet
  },
  data() {
    return {
      active: 'tous',
      projets: [
        {
          id: 1,
          titre: "Mmi d'or",
          img: "mmidor.png",
          likes: 1,
          type: 'graphisme'
        },
        {
          id: 2,
          titre: "Mmi d'or",
          img: "mmidor.png",
          likes: 50,
          type: 'pao'
        },
        {
          id: 3,
          titre: "Mmi d'or",
          img: "mmidor.png",
          likes: 50,
          type: 'ui'
        },
        {
          id: 4,
          titre: "Mmi d'or",
          img: "mmidor.png",
          likes: 50,
        },
      ],
      projetsToShow: [],
    }
  },
  methods: {
    updateFilter() {
      this.projetsToShow = this.projets.filter((projet) => {
        if(this.active === 'tous')
          return projet;

        if(projet.type === this.active)
            return projet;

      })
    }
  },
  created() {
    this.projetsToShow = JSON.parse(JSON.stringify(this.projets));
  },
  watch: {
    active() {
      this.updateFilter();
    }
  }
}
</script>