<template>
    <section class="accueil">
        <div class="title-container">
            <div class="title">
                <img src="SVG/svgHome/PORTFOLIOVICTORWALLART.svg" />
                <img class="title-buche" src="SVG/svgHome/Buche_accueil.svg"/>
                <img class="title-plant" src="SVG/svgHome/PotPlante_accueil.svg"/>
                <img class="title-spark" src="SVG/svgHome/Spark_x3_couleurjaune_accueil.svg"/>
            </div>
        </div>
        <img class="parallax accueil-nuage" style="top: 35vh; left: 10vw" data-top="35vh" data-left="10vw" src="SVG/svgHome/Cloud_hautgauche_accueil.svg"/>
        <img class="parallax accueil-nuage2" style="top: 78vh; left: 80vw" data-top="78vh" data-left="80vw" src="SVG/svgHome/Cloud_basdroite_accueil.svg"/>
        <img class="parallax accueil-spark1" style="top: 70vh; left: 20vw" data-top="70vh" data-left="20vw" src="SVG/svgHome/Spark_x2_BasDroite_accueil.svg"/>
        <img class="parallax accueil-spark2" style="top: 25vh; left: 80vw" data-top="25vh" data-left="80vw" src="SVG/svgHome/Spark_x2_HautDroite_accueil.svg"/>
        <img class="parallax accueil-spark3" style="top: 50%; left: 92vw" data-top="50%" data-left="92vw" src="SVG/svgHome/Spark_x1_couleurvert_accueil.svg"/>
        <img class="parallax accueil-spark4" style="top: 70vh; left: 95vw" data-top="70vh" data-left="95vw" src="SVG/svgHome/Spark_x1_BasDroite_accueil.svg"/>
        <div class="cv-container">
            <div class="cv-button">
                <a href="#">Mon CV</a>
                <img src="SVG/svgHome/CarnetCV_accueil.svg"/>
            </div>
        </div>
        <img class="mouse" src="SVG/svgHome/Mouse.svg"/>
        <div class="homepage-circle-text-container">
            <div class="homepage-circle-text">
                <svg viewBox="0 0 100 100" width="100" height="100">
                    <defs>
                        <path id="circle"
                              d="
                                M 50, 50
                                m -37, 0
                                a 37,37 0 1,1 74,0
                                a 37,37 0 1,1 -74,0"/>
                    </defs>
                    <text font-size="17">
                        <textPath xlink:href="#circle">
                            - graphiste - freelance
                        </textPath>
                    </text>
                </svg>
            </div>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  mounted() {
      document.addEventListener("mousemove", parallax);
      const elements = document.getElementsByClassName("parallax");
      // Magic happens here
      function parallax(e) {
          for(let element of elements){
              if(element.dataset.randX === undefined){
                  element.dataset.randX = randomIntFromInterval(1, 7);
              }
              if(element.dataset.randY === undefined){
                  element.dataset.randY = randomIntFromInterval(1, 7);
              }
              let _w = window.innerWidth/2;
              let _h = window.innerHeight/2;
              let _mouseX = e.clientX;
              let _mouseY = e.clientY;
              let _depth1 = `calc(${element.dataset.top} - ${(_mouseY - _h) * element.dataset.randX}%)`;
              let _depth2 = `calc(${element.dataset.left} - ${(_mouseX - _w) * element.dataset.randY}%)`;
              element.style.top = _depth1;
              element.style.left = _depth2;
          }
      }
      function randomIntFromInterval(min, max) { // min and max included
          return Math.floor(Math.random() * (max - min + 1) + min) / 10000
      }
  }
}
</script>
