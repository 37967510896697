<template>
  <shareModal :sharingProject="sharingProject" v-if="toggleShareModal"/>
  <header>
      <div class="logo">
          <a href="">
            <img src="SVG/V_accueil.svg" />
          </a>
      </div>
      <nav class="menu-link">
          <a href="#about">a propos</a>
          <a href="#projet">Projets</a>
          <a href="#contact">contact</a>
      </nav>
  </header>
  <router-view/>
</template>
<script>
  import shareModal from '@/components/ShareModal'
  export default {
    components: {
      shareModal
    },
    data: function(){
      return {
        toggleShareModal: false,
        sharingProject: 0,
      };
    },
    methods: {
      showShareProjectModal: function (projectId = 0) {
        this.toggleShareModal = !this.toggleShareModal;
        this.sharingProject = projectId;
      },
    }
  }
</script>