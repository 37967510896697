<template>
  <div class="share-modal-container">
    <div class="share-modal-background" @click="(event) => { clickOnBackground(event) }">
        <div class="share-modal">
          <div class="share-modal-title">
              Partager un projet
          </div>
          <div class="share-modal-button-list">
              <div class="share-button" @click="shareFacebook">
                  Facebook
              </div>
              <div class="share-button" @click="shareTwitter">
                  Twitter
              </div>
              <div class="share-button" @click="shareLinkedIn">
                  LinkedIn
              </div>
              <div class="share-button" @click="shareMail">
                  Email
              </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'shareModal',
    props: {
      sharingProject: Number,
    },
    methods: {
        shareFacebook: function () {
            window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href,
                'facebook-share-dialog',
                'width=800,height=600'
            );
        },
        shareTwitter: function () {
            window.open('https://twitter.com/intent/tweet?text=' + window.location.href,
                'twitter-share-dialog',
                'width=800,height=600'
            );
        },
        shareLinkedIn: function () {
            window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + window.location.href,
                'linkedin-share-dialog',
                'width=800,height=600'
            );
        },
        shareMail: function () {
            var formattedSubject = "Regarde ce projet de fou !";
            var formattedBody = window.location.href;
            var mailToLink = "mailto:?subject=" + encodeURIComponent(formattedSubject) + "&body=" + encodeURIComponent(formattedBody);
            window.location.href = mailToLink;
        },
        clickOnBackground: function(event){
            if(event.target === event.currentTarget){
                this.$root.showShareProjectModal();
            }
        }
    }
}
</script>