<template>
  <HomeView></HomeView>
  <AboutView></AboutView>
  <ProjetView></ProjetView>
  <ContactView></ContactView>
</template>
<script>
import HomeView from "@/views/HomeView.vue";
import AboutView from "@/views/AboutView.vue";
import ProjetView from "@/views/ProjetView.vue";
import ContactView from "@/views/ContactView.vue";

export default {
    name: "Accueil",
    components: {
        HomeView,
        AboutView,
        ProjetView,
        ContactView,
    }
}
</script>