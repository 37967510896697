<template>
  <div class="projet-item">
    <div class="projet-item-thumbnail">
      <img :src="'projets/' + projet.img"/>
    </div>
    <div class="projet-item-data">
      <div class="projet-item-title">
        <h5>{{ projet.titre }}</h5>
        <div class="projet-item-share" @click="shareProject">
          <img class="projet-item-share-icon" src="SVG/svgProjet/partager_icon.svg"/>
          <img class="projet-item-share-text" src="SVG/svgProjet/partager.svg"/>
        </div>
      </div>
      <div class="projet-item-likes">
        <img src="SVG/svgProjet/coeur.svg"/>
        <span>{{ projet.likes }} {{ projet.likes > 1 ? "J'aimes" : "J'aime" }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Projet",
  props:{
    projet: Object
  },
  methods: {
    shareProject: function (){
        this.$root.showShareProjectModal(this.projet.id);
    }
  }
}
</script>