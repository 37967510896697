<template>
  <section class="contact" id="contact">
    <img class="parallax contact-cloud" style="top: 78vh; left: 15vw" data-top="78vh" data-left="15vw" src="SVG/svgHome/Cloud_basdroite_accueil.svg"/>
    <img class="parallax contact-cloud2" style="top: 35vh; left: 75vw" data-top="35vh" data-left="75vw" src="SVG/svgHome/Cloud_hautgauche_accueil.svg"/>
    <img class="parallax contact-spark" style="top: 25vh; left: 10vw" data-top="25vh" data-left="10vw" src="SVG/svgHome/Spark_x2_BasDroite_accueil.svg"/>
    <img class="parallax contact-spark2" style="top: 85vh; left: 90vw" data-top="85vh" data-left="90vw" src="SVG/svgHome/Spark_x2_HautDroite_accueil.svg"/>
    <div class="contact-title">
        <img src="SVG/svgContact/CONTACTEZ-MOI_text.svg"/>
    </div>
    <div class="contact-liste-support">
      <div class="contact-support-container">
        <img class="contact-support-enveloppe" src="SVG/svgContact/enveloppe.svg">
        <a target="_blank" href="mailto:vivi.wallart59@gmail.com">e-mail</a>
      </div>
      <div class="contact-support-container">
        <img class="contact-support-photo" src="SVG/svgContact/appareil_photo.svg">
        <a target="_blank" href="https://www.instagram.com/victorwlt/">instagram</a>
      </div>
      <div class="contact-support-container">
        <img class="contact-support-oiseau" src="SVG/svgContact/oiseau.svg">
        <a target="_blank" href="https://www.linkedin.com/in/victor-wallart-99767a273">linkedin</a>
      </div>
    </div>
  </section>
</template>
<script>

export default {
    name: "ContactView",
}
</script>